<template>
  <div class="page">
    <div class="page-top">
      <el-button type="primary" @click="$router.push('/addshop')"
        >新增门店</el-button
      >
    </div>
    <div class="page-bot">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column type="index" label="序号" align="center" />
        <el-table-column prop="shopName" label="门店名称" align="center" />
        <el-table-column prop="phone" label="联系电话" align="center" />
      </el-table>
    </div>
  </div>
</template>
<script>
import { queryShopList } from "@/api/product/index";
export default {
  data() {
    return {
      tableData: [],
    };
  },
  created() {
    this.queryShopList();
  },
  methods: {
    queryShopList() {
      let data = {
        params: {
          merCode: JSON.parse(sessionStorage.getItem("LoginInfo")).merCode,
          merType: JSON.parse(sessionStorage.getItem("LoginInfo")).merType,
        },
      };
      queryShopList(data).then((res) => {
        if (res.data.flag) {
          this.tableData = res.data.data;
        }else{
          this.$message.error(res.data.msg)
        }
      });
    },

  },
};
</script>
<style scoped>
.page {
  min-height: calc(100vh - 270px);
}
.page-top {
  padding: 10px;
}
.page-bot {
  padding: 10px;
}
</style>